import React, { Component } from "react";

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: [
        {
          id: 1,
          img: "./images/img/logo.png",
          alt: "images",
          text01:
            "İkinci Çerçeve Psikoloji 2022 yılında Uzman Klinik Psikolog Eyyüp Durmaz tarafından şubat ayında kurulmuştur. İkinci Çerçeve Psikoloji Psk. Eyyüp Durmaz ve ekibiyle yetişkinlere, ergenlere, çocuklara ve çiftlere danışmanlık hizmetlerinin yanı sıra eğitim, atölye ve seminerler de düzenlemektedir.",
          text02:
            " İçerisinde bulundurduğu uzman çeşitliliği ile farklı ekollerde farklı teknikler kullanarak danışanlarına doğru ve etkili hizmet vermeyi amaçlamaktadır. ",
          text03:
            "Şu an Bakırköy ve Nişantaşı olmak üzere iki ayrı klinikte çalışmalarına devam etmektedir.",
        //   name: "Devamını Oku...",
          classname: "testimonial-t1 text-center",
        },
      ],
    };
  }
  render() {
    return (
      <div className="container">
        <div className="custom-dot dot-t1 dot-s1 none-nav-default mg-dots-s1">
          <div
            className="flat-carousel-box data-effect clearfix"
            data-gap={0}
            data-column={1}
            data-column2={1}
            data-column3={1}
            data-column4={1}
            data-column5={1}
            data-dots="true"
            data-auto="false"
            data-nav="false"
            data-loop="true"
          >
            <div className="owl-carousel">
              {this.state.testimonial.map((data) => (
                <div className={data.classname} key={data.id}>
                  <div className="avatar mg-b29">
                    <img src={data.img} alt={data.alt} />
                  </div>
                  <p className="lt-sp03 mg-b25">
                    {data.text01}
                    <br /> {data.text02} <br />
                    {data.text03}
                  </p>
                  {/* <h3 className="name f-w600">{data.name}</h3> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
