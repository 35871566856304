import React, { Component } from "react";
import { Link } from "react-router-dom";
class Footer extends Component {
  render() {
    return (
      <div
        className="section slide-personal-Intro-sixth slide-dark"
        id="contact"
      >
        <footer id="footer" className="footer footer-s1 footer-s1-home1">
          <div
            id="footer-widget"
            className="footer-widget-s1 footer-widget-line bg-s1 position-relative"
          >
            <div className="container">
              <div className="row d-lg-flex align-items-center text-center">
                <div className="col-lg-12">
                  <h3 className="widget-title larger lt-sp06 color-d12">
                    İLETİŞİM
                  </h3>
                </div>
                <div className="col-lg-12">
                  <div className="widget-info">
                    <p   style={{ paddingBottom: '10px' }} className="mail">
                      <a className="mail" href="tel:05434971764">
                        Bakırköy <br />
                        +90 (543) 497 17 64
                      </a>
                    </p>
                    <p style={{ paddingBottom: '10px' }} className="mail">
                      <a  className="mail" href="tel:05444971764">
                        Nişantaşı <br />
                        +90 (544) 497 17 64
                      </a>
                    </p>
                    <p className="phone">
                      <a
                        className="phone"
                        href="mailto:pskeyupdurmaz@gmail.com"
                      >
                        pskeyupdurmaz@gmail.com
                      </a>
                    </p>
                    <p className="phone">
                      <a
                        className="phone"
                        href="mailto:ikincicerceve@gmail.com"
                      >
                        ikincicerceve@gmail.com
                      </a>
                    </p>
                    <p className="address">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.google.com/maps/place/Psikolog+Ey%C3%BCp+Durmaz+%C5%9Ei%C5%9Fli+Aile+%C3%87ift+Cinsel+Terapi+Ni%C5%9Fanta%C5%9F%C4%B1+En+iyi+Psikolog+Ni%C5%9Fanta%C5%9F%C4%B1+Psikolog+%C3%96nerisi+%C5%9Ei%C5%9Fli+En+iyi+Psikolog/@41.0546842,28.9869068,20z/data=!4m5!3m4!1s0x14cabb88319b79d7:0xfdf38b376c95ca68!8m2!3d41.054588!4d28.987383?hl=tr&coh=164777&entry=tt&shorturl=1"
                      >
                        Halaskargazi Cad. No:102 Daire:5 Birlik Apt.
                        Şişli/İstanbul
                      </a>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.google.com/maps/dir//psikolog+ey%C3%BCp+durmaz/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x14cabb728e2573f3:0xf89c77423640364d?sa=X&ved=2ahUKEwinpffwq4D8AhWAXvEDHfPAD5kQ9Rd6BAhPEAQ"
                      >
                        Kartaltepe Necmettin Sadak Sk. No:3/1 Daire:5, 34140
                        Bakırköy/İstanbul
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="site-list site-list-pdl text-center">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/ey%C3%BCp-durmaz-4932261b0/"
                      className="bg-s1"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </a>
                    <a
                      target="_blank"
                      href="https://wa.me/+905434971764"
                      className="bg-s2"
                    >
                      <i className="fa fa-whatsapp" aria-hidden="true" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/psikologeyupdurmaz/"
                      className="bg-s3"
                    >
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="bottom" className="bottom-s1">
            <div className="container d-lg-flex justify-content-center">
              <div className="copyright lt-sp02">
                Eyüp Durmaz © 2023 Tüm hakları saklıdır.
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
