import React, { Component } from 'react';

class ContainerLeft extends Component {
    render() {
        return (
            <div className="col-left animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                <div className="flat-title t1">
                    {/* <h4 className="sub-title mb-2">Seminerler</h4> */}
                    <h2 className="title-section color-d12 mg-b34">Seminerler</h2>
                    <div className="description">
                        <p className="lt-sp01">
                        Uzman kadromuz ile ihtiyaç duyduğunuz tüm konularda sizler için eğitim ve seminerler düzenliyoruz. Verdiğimiz eğitim ve seminerler çeşitli hedef gruplarına yönelik olup,
                        </p>
                        <p className="lt-sp01">
                        kişilerin kendilerini daha iyi tanımalarını sağlamayı ve psikolojik bilgiler doğrultusunda mevcut alandaki gelişimlerine katkıda bulunmayı hedeflemektedir.
                        </p>
                    </div>
                    <div className="fl-btn btn-general btn-hv-border">
                        {/* <Link to="#" className="f-w500 text-one color-1 lt-sp1 border-corner2">View Case Study</Link> */} 
                       </div>
                </div>
          </div>
        );
    }
}

export default ContainerLeft;
