import React, { useEffect } from "react";
import styled from "styled-components";

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  transition: width 2s;
  &::before {
    content: "";
    background: #202833;
    position: fixed;
    block-size: ${(props) => (props.isLoaderShow ? "100%" : "0%")};
    inline-size: 100%;
    transition: height 2s;
    z-index: 999999;
  }

  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #16a085;
    -webkit-animation: spin 1.7s linear infinite;
    animation: spin 1.7s linear infinite;
    z-index: ${(props) => (props.isLoaderShow ? 999999 : -1)};

  }
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;
    -webkit-animation: spin-reverse 0.6s linear infinite;
    animation: spin-reverse 0.6s linear infinite;
    z-index: 999999;
    z-index: ${(props) => (props.isLoaderShow ? 999999 : -1)};

  }
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    z-index: ${(props) => (props.isLoaderShow ? 999999 : -1)};
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin-reverse {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  #loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    z-index: ${(props) => (props.isLoaderShow ? 999999 : -1)};
  }

  #loader-wrapper .loader-section.section-left {
    left: 0;
  }

  #loader-wrapper .loader-section.section-right {
    right: 0;
  }

  /* Loaded styles */
  .loaded #loader-wrapper .loader-section.section-left {
    transform: translateX(-100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .loaded #loader-wrapper .loader-section.section-right {
    transform: translateX(100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .loaded #loader {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  .loaded #loader-wrapper {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.3s 1s ease-out;
  }
`;

export default function Loader({ loading, setLoading }) {
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 1800);
    }
  }, [loading]);

  return (
    <Overlay isLoaderShow={loading}>
      <div id="loader-wrapper">
        <div id="loader"></div>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>
    </Overlay>
  );
}
