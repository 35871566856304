import React, { Component } from "react";
class Banner extends Component {
  render() {
    return (
      <div className="section slide-personal-Intro-first">
        <section className="banner-section s1" id="home">
          <div className="container">
            <div className="content-text position-relative">
            <div className="mobile-banner-image">
                <img src="/images/img/img.png" alt="hero-1" />
              </div>
              <div
                className="animate-element wow delay5 fadeInDown banner-mobile"
                data-wow-delay="0.3s"
              >
                <h1 className="cd-headline clip is-full-width title mg-b29 text-white">
                  {/* <div className="mobile-image">
                    <img src="/images/img/img.png" alt="hero-1" />
                  </div> */}  
                  <span className="mr-5">Uzman Klinik Psikolog </span> <br/>
                  <span className="cd-words-wrapper color-d4">
                    <b className="is-visible">Eyüp Durmaz</b>
                    {/* <b>Eyüp Durmaz</b> */}
                  </span>
                </h1>
                <p className="lt-sp03 mg-b60 text-white">
                  Uzman Psikolog Eyüp Durmaz, İstanbul Gelişim Üniversitesinde
                  Psikoloji Lisansını yapmıştır.
                  <br />
                  Lisans öğrenimi sırasında,  katıldığı konferanslar ve yapmış
                  olduğu İstanbul Gelişim Üniversitesi Psikoloji Kulüp
                  Başkanlığı, Türk Psikoloji Öğrenci Çalışma Grubu (TPÖÇG)
                  Üniversite Temsilciliği, Sosyal sorumluluk projeleri, Akademik
                  Etkinlik Liderliği çalışmalarıyla alan etkinliğini arttırmaya
                  ilk adımlarını atmıştır.
                </p>
              </div>
              <div className="banner-image">
                <img src="/images/img/img.png" alt="hero-1" />
              </div>
              <div
                className="animate-element wow delay5 fadeInUp"
                data-wow-delay="0.5s"
              >
                {/* <div className="fl-btn btn-general btn-hv-border">
                  <Link
                    to="#about"
                    className="border-corner5 f-w500 lt-sp095 text-white click-model"
                  >
                    İletişim
                  </Link> 
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Banner;
