import React, { Component } from "react";
import { Link } from "react-router-dom";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: [
        {
          id: 1,
          classname: "profile-link border-corner2 d-flex align-items-center",
          // images: "./images/section/05.png",
          alt: "images",
          name: "Catell",
          normal: "2A Zeka Testi",
        },
        {
          id: 2,
          classname: "profile-link border-corner2 d-flex align-items-center",
          // images: "./images/section/06.png",
          alt: "images",
          name: "Catell ",
          normal: "3A Zeka Testi",
        },
        {
          id: 3,
          classname: "profile-link border-corner2 d-flex align-items-center",
          // images: "./images/section/07.png",
          alt: "images",
          name: "Alexander",
          normal: "Pratik Yetenek Zeka Testi",
        },
        {
          id: 4,
          classname: "profile-link border-corner2 d-flex align-items-center",
          // images: "./images/section/08.png",
          alt: "images",
          name: "Gesell",
          normal: "Gelişim Testi",
        },
      ],
    };
  }
  render() {
    return (
      <div className="background-white">
        <div className="d-lg-flex">
          <div className="col-left">
            <div
              className="featured-post animate-element wow delay5 fadeInUp"
              data-wow-delay="0.5s"
            >
                <div
              className="flat-spacer"
              data-desktop="105"
              data-mobile="50"
              data-smobile="50"
            ></div>
            <div className="flat-title t1">
              <div
                className="animate-element wow delay5 fadeInDown"
                data-wow-delay="0.5s"
              >
                <h4 className="sub-title mg-b22">Eyüp Durmaz</h4>
                <h2 className="title-section mg-b26 color-d12">
                Yolunu Kendi 
                  <span className="color-d4"> Işığınla Aydınlat</span>
                </h2>
                <p>
                  Uzman Psikolog Eyüp Durmaz, İstanbul Gelişim Üniversitesinde
                  Psikoloji Lisansını yapmıştır. Lisans öğrenimi sırasında,
                  katıldığı konferanslar ve yapmış olduğu İstanbul Gelişim
                  Üniversitesi Psikoloji Kulüp Başkanlığı, Türk Psikoloji
                  Öğrenci Çalışma Grubu (TPÖÇG) Üniversite Temsilciliği, Sosyal
                  sorumluluk projeleri, Akademik Etkinlik Liderliği
                  çalışmalarıyla alan etkinliğini arttırmaya ilk adımlarını
                  atmıştır.
                </p>
              </div>
              <div
                className="animate-element wow delay5 fadeInUp"
                data-wow-delay="0.5s"
              >
                {/* <div className="fl-btn btn-general">
                  <Link to="#" className="f-w500 color-1 lt-sp07">
                  Detay Görüntüle
                  </Link>
                </div> */}
              </div>
            </div>
            </div>
          </div>
          <div className="col-right">
          <img src="images/img/MyPhoto.png" alt="images" />

          
          </div>
        </div>
        <div className="profile-list">
          <div
            className="flat-carousel-box data-effect clearfix"
            data-gap="30"
            data-column="4"
            data-column2="3"
            data-column3="2"
            data-column4="1"
            data-column5="1"
            data-dots="false"
            data-auto="false"
            data-nav="false"
            data-loop="true"
          >
            <div className="owl-carousel">
              {this.state.profile.map((data) => (
                <div className={data.classname} key={data.id}>
                  <div className="featured-post">
                    {/* <img src={data.images} alt={data.alt} /> */}
                  </div>
                  <div className="content-inside">
                    <h3 className="name">
                      <Link to="#">{data.name}</Link>
                    </h3>
                    <span className="t-normal">{data.normal}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
