const menus = [
  {
    id: 1,
    tomenu: "#home",
    namemenu: "Anasayfa",
  },
  {
    id: 2,
    tomenu: "#testimonial",
    namemenu: "İkinci Çerveve",
  },
  {
    id: 3,
    tomenu: "#about",
    namemenu: "Hakkımda",
  },
  {
    id: 4,
    tomenu: "#services",
    namemenu: "Hizmetler",
  },
  {
    id: 5,
    tomenu: "#portfolio",
    namemenu: "Etkinlik",
  },

  {
    id: 6,
    tomenu: "#resume",
    namemenu: "Eğitimler",
  },
];

export default menus;
