import React, { Component } from "react";
import { Design, NumberCount } from "./specilizing/index";

class Specilizing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      design: [
        {
          id: 1,
          iconbox: "iconbox-s1 d-sm-flex align-items-center",
          iconcolor: "iconbox-icon color1",
          spanicon: "icon-designer",
          title: "Bireysel Terapi",
          text01:
            "Bireysel terapi danışanın psikoterapistiyle birebir olarak çalışmasıdır.",
          text02:
            " Amaç kişinin, kendisini somatik tepkiler, davranışlar, inanışlar, ilişki problemleri veya ",
          text03:
            "duygular olarak gösteren iç sancılarını hafifletmeye yöneliktir.",
        },
        {
          id: 2,
          iconbox:
            "iconbox-s1 d-sm-flex justify-content-lg-center align-items-center mgl-iconbox-s1",
          iconcolor: "iconbox-icon color2 color1",
          spanicon: "icon-designer",
          title: "Aile ve Çift Terapisi",
          text01:
            "Aile terapisi, değişimi ve gelişimi beslemek için aileler ve çiftlerle",
          text02:
            "yakın ilişkiler içinde çalışan bir psikoloji ve klinik sosyal hizmet dalıdır.",
          text03:
            "Değişimi aile üyeleri arasındaki etkileşim sistemleri açısından görme eğilimindedir.",
        },
        {
          id: 3,
          iconbox:
            "iconbox-s1 d-sm-flex justify-content-lg-end align-items-center mgr-iconbox-s1",
          iconcolor: "iconbox-icon color3",
          spanicon: "icon-brand",
          title: "Cinsel Terapi",
          text01:
            "Cinsel terapi karşılıklı diyalog, paylaşım ve uzlaşı üzerine kurulu bir konuşma terapisi uygulamasıdır.",
          text02:
            "Terapide yer alan kişiler tıbbi, psikolojik ya da kişisel problemlerinden hangilerinin ",
          text03:
            "cinsel yaşam içinde bir problem haline geldiğinin tespitine yönelik olarak iletişim kurarlar.",
        },
      ],
    };
  }
  render() {
    return (
      <div className="section slide-personal-Intro-third slide-dark bg-white">
        <section className="specilizing-in s1" id="services">
          <div className="container">
            <div className="flat-title">
              <h2
                className="title-section color-d12 animate-element wow delay5 fadeInDown"
                data-wow-delay="0.5s"
              >
                Çalışma Alanlarım
              </h2>
            </div>
            <div className="row position-relative z-index1">
              {this.state.design.map((data) => (
                <Design key={data.id} data={data} />
              ))}
              <div
                className="flat-spacer"
                data-desktop="0"
                data-mobile="0"
                data-smobile="0"
              ></div>
              <div className="fact">
                <NumberCount />
                <div className="btn-contact bg-s1 text-center">
                  <h4 className="title color-d12">Randevu için</h4>
                  <a href="tel:05434971764" className=" color-s1 color-d14">
                    (543) 497 1764
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="featured-post animate-element wow delay5 fadeInRight"
            data-wow-delay="0.5s"
          >
            <img src="images/section/09.png" alt="images" />
          </div>
        </section>
      </div>
    );
  }
}

export default Specilizing;
