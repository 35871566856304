import React, { Component } from "react";
import { Link } from "react-router-dom";
class EducationLeft extends Component {
  render() {
    return (
      <div className="col-left">
        <div
          className="flat-spacer"
          data-desktop={97}
          data-mobile={0}
          data-smobile={0}
        />
        <div
          className="featured-post position-relative animate-element wow delay5 fadeInUp"
          data-wow-delay="0.5s"
        >
          <img src="/images/img/educationss.jpg" alt="images" />
          <div
            className="flat-spacer"
            data-desktop={0}
            data-mobile={0}
            data-smobile={30}
          />
          <div className="">
            <a
              target="_blank"
              href="https://www.linkedin.com/in/ey%C3%BCp-durmaz-4932261b0/"
              className="color-s1"
            >
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a>
            <a
              target="_blank"
              href="https://wa.me/+905434971764"
              className="color-s2"
            >
              <i className="fa fa-whatsapp" aria-hidden="true" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/psikologeyupdurmaz/"
              className="color-s3"
            >
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default EducationLeft;
